import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Link as LinkG } from 'gatsby';
import styled from 'styled-components';

export default function NavbarLogo() {
  return (
    <Logo>
      <LinkG to='/'>
        <StaticImage
          src='../../images/overquality-logo.png'
          alt='Overquality logo'
          placeholder='blurred'
        />
      </LinkG>
    </Logo>
  );
}

const Logo = styled.div`
  cursor: pointer;
`;
