import React from 'react';
import DefaultLayout from '../layouts/Default';
import Hero from '../components/main/Hero';
import About from '../components/main/About';
import Services from '../components/main/Services';
import Projects from '../components/main/Projects';
import CallToAction from '../components/global/CallToAction';
import Clients from '../components/main/Clients';
import { graphql } from 'gatsby';
import Seo from '../components/global/Seo';
import SideNav from '../components/main/SideNav';
import ContactForm from '../components/global/ContactForm';

// markup
const IndexPage = ({ data }) => {
  const projects = data.allMarkdownRemark.nodes;
  return (
    <DefaultLayout>
      <Seo title='Κατασκευή ιστοσελίδων - Branding Αθήνα' />
      <Hero />
      <About />
      <Services />
      <Projects projects={projects} />
      <CallToAction />
      <Clients />
      <ContactForm />
      <SideNav />
    </DefaultLayout>
  );
};

export const query = graphql`
  query Projects {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/(projects)/" } }
      sort: { order: ASC, fields: frontmatter___title }
    ) {
      nodes {
        frontmatter {
          title
          link
          thumbAlt
          thumb {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
            }
          }
        }
        id
      }
    }
  }
`;

export default IndexPage;
