import React from 'react';
import styled from 'styled-components';
import { Link as LinkS } from 'react-scroll';

export default function SideNav() {
  const data = [
    {
      name: 'Αρχη',
      id: 'header',
    },
    {
      name: 'Εταιρεια',
      id: 'about',
    },
    {
      name: 'Υπηρεσιες',
      id: 'services',
    },
    {
      name: 'Projects',
      id: 'projects',
    },
    {
      name: 'Clients',
      id: 'clients',
    },
    {
      name: 'Επικοινωνια',
      id: 'contact',
    },
  ];

  return (
    <SideNavWrap>
      {data.map((sidelink, index) => {
        return (
          <SideNavItem key={index}>
            <SideNavItemCircle
              to={sidelink.id}
              activeClass='sidenav-link-active'
              spy={true}
              smooth={true}
              duration={700}
            ></SideNavItemCircle>
            <SideNavItemLabel>{sidelink.name}</SideNavItemLabel>
          </SideNavItem>
        );
      })}
    </SideNavWrap>
  );
}

const SideNavWrap = styled.nav`
  padding: 0.938rem;
  position: fixed;
  gap: 0.8rem;
  display: flex;
  flex-direction: column;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  .sidenav-link-active {
    color: #000;
    background: #46da92;
    transform: scale(1.4);
  }
`;

const SideNavItem = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  gap: 1.5rem;
  transition: transform 0.2s;
  height: 1rem;
`;

const SideNavItemLabel = styled.span`
  color: #46da92;
  font-weight: 700;
  display: none;
  text-transform: uppercase;
  transition: opacity 0.2s;
`;

const SideNavItemCircle = styled(LinkS)`
  background: rgba(0, 0, 0, 0);
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  transition: transform 0.2s;
  cursor: pointer;
  border: 1px solid #46da92;

  &:hover ~ ${SideNavItemLabel} {
    display: block;
  }
`;
