import React from 'react';
import { Link as LinkS } from 'react-scroll';
import styled from 'styled-components';

export default function Navbar() {
  const data = [
    {
      name: 'Εταιρεια',
      id: 'about',
    },
    {
      name: 'Υπηρεσιες',
      id: 'services',
    },
    {
      name: 'Projects',
      id: 'projects',
    },
    {
      name: 'Clients',
      id: 'clients',
    },
    {
      name: 'Επικοινωνια',
      id: 'contact',
    },
  ];

  return (
    <NavBar>
      {data.map((link, index) => {
        return (
          <NavBarLink key={index}>
            <LinkS smooth={true} to={link.id} title={link.name}>
              {link.name}
            </LinkS>
          </NavBarLink>
        );
      })}
    </NavBar>
  );
}

const NavBar = styled.nav`
  display: flex;
  gap: 1rem;

  @media screen and (min-width: 280px) and (max-width: 768px) {
    display: none;
  }
`;
const NavBarLink = styled.div`
  a {
    text-decoration: none;
    color: #000;
    text-transform: uppercase;
    cursor: pointer;
    transition-duration: 0.2s;
    &:hover {
      color: #46da92;
    }
  }
`;
