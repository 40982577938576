import React from 'react';
import styled from 'styled-components';
import ProjectsSingle from './ProjectsSingle';

export default function Projects(props) {
  return (
    <ProjectsSection id='projects'>
      <div className='section-inner'>
        <div className='section-headings-wrap'>
          <h2 className='green-headings'>Projects</h2>
          <h3>Δείτε μερικά απο τα project μας</h3>
        </div>
        <ProjectsGrid>
          {props.projects.map((project, id) => {
            return <ProjectsSingle project={project} key={id} />;
          })}
        </ProjectsGrid>
      </div>
    </ProjectsSection>
  );
}

const ProjectsSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 3.125rem;
`;

const ProjectsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 3.25rem;
  row-gap: 6.25rem;
  justify-items: center;
  @media screen and (max-width: 960px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 640px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
