import React from 'react';
import styled from 'styled-components';
import Logo from './Logo';
import Navbar from './Navbar';

export default function Header() {
  return (
    <HeaderSection id='header'>
      <Logo />
      <Navbar />
    </HeaderSection>
  );
}

const HeaderSection = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.75rem;
  z-index: 1;
  width: 100%;
`;
