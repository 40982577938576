import React from 'react';
import styled from 'styled-components';
import backgroundImage from '../../images/about-background.png';
import { StaticImage } from 'gatsby-plugin-image';

export default function About() {
  return (
    <AboutSection id='about'>
      <div className='section-inner'>
        <AboutLeft>
          <h3>Υπηρεσίες υψηλής ποιότητας</h3>
        </AboutLeft>
        <AboutRight>
          <p>
            Είμαστε μια ομάδα εξειδικευμένων επαγγελματιών που μας ενώνει ένας
            κοινός στόχος: η δημιουργία έργων που να ξεχωρίζουν και να
            ικανοποιούν πλήρως τους πελάτες - συνεργάτες μας. <br />
            <br />
            Το εύρος των υπηρεσιών μας, η υψηλή τεχνογνωσία μας, η εμπειρία μας
            στο χώρο του internet και ο ανθρωποκεντρικός χαρακτήρας μας, θα
            φτάσει την επιχείρηση σας ψηλά. Καλέστε μας για να μελετήσουμε τις
            ανάγκες της επιχείρησής σας και να σας προτείνουμε τις κατάλληλες
            λύσεις.
          </p>
        </AboutRight>
      </div>
      <StaticImage
        src='../../images/man-holding-tablet-overquality.png'
        alt='Ένας άντρας κρατάει ένα tablet'
        className='center-image'
        placeholder='blurred'
      />
    </AboutSection>
  );
}

const AboutSection = styled.section`
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  row-gap: 2.5rem;
  @media screen and (max-width: 960px) {
    flex-direction: column;
  }

  .center-image {
    position: absolute;
    @media screen and (max-width: 960px) {
      display: none;
    }
  }
`;

const AboutLeft = styled.div`
  display: flex;
  flex-direction: row;
  background: url(${backgroundImage}) no-repeat;
  background-size: cover;
  width: 50%;
  align-items: center;

  @media screen and (min-width: 1340px) {
    justify-content: center;
  }
  @media screen and (max-width: 960px) {
    width: 100%;
  }

  h3 {
    width: 75%;
    margin-top: 4.688rem;
    @media screen and (min-width: 1340px) {
      width: 65%;
    }
  }
`;

const AboutRight = styled.div`
  display: flex;
  width: 50%;
  justify-content: flex-end;
  height: 18.75rem;
  align-items: flex-end;
  @media screen and (min-width: 1340px) {
    justify-content: center;
  }
  @media screen and (max-width: 960px) {
    width: 100%;
    justify-content: center;
    height: auto;
  }
  p {
    width: 75%;
    @media screen and (min-width: 1340px) {
      width: 65%;
    }
    @media screen and (max-width: 640px) {
      width: 90%;
    }
  }
`;
