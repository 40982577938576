import React from 'react';
import styled from 'styled-components';
import ServiceSingle from './ServicesSingle';
import backgroundImage from '../../images/green-circle-background.png';
import { useStaticQuery, graphql } from 'gatsby';

export default function Services() {
  const data = useStaticQuery(graphql`
    query Services {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/(services)/" } }
        sort: { order: ASC, fields: frontmatter___title }
      ) {
        nodes {
          frontmatter {
            title
            description
          }
          id
        }
      }
    }
  `);
  const services = data.allMarkdownRemark.nodes;

  return (
    <ServicesSection id='services'>
      <div className='section-inner'>
        <div className='section-headings-wrap'>
          <h2 className='green-headings'>Υπηρεσιες</h2>
          <h3>Ο,τι χρειάζεστε για την επιχείρηση σας</h3>
        </div>
        <ServicesGrid>
          {services.map((service, id) => {
            return <ServiceSingle service={service} key={id} />;
          })}
        </ServicesGrid>
      </div>
    </ServicesSection>
  );
}

const ServicesSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 3.125rem;
  background: url(${backgroundImage}) no-repeat bottom right;
`;

const ServicesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 7.5rem;
  row-gap: 6.25rem;
  width: 70%;
  margin: auto;
  @media screen and (max-width: 960px) {
    width: 100%;
  }
  @media screen and (max-width: 640px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
