import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import styled from 'styled-components';

export default function Clients() {
  return (
    <ClientsSection id='clients' data-color='white'>
      <div className='section-inner'>
        <div className='section-headings-wrap'>
          <h2 className='green-headings'>Clients</h2>
          <h3>Μας εμπιστεύτηκαν.</h3>
        </div>
        <ClientsGrid>
          <StaticImage
            src='../../images/clients/clients-healthchem-logo.png'
            alt='Λογότυπο της εταιρείας Healthchem'
            placeholder='blurred'
          />
          <StaticImage
            src='../../images/clients/clients-madcap-logo.png'
            alt='Λογότυπο της εταιρείας Madcap'
            placeholder='blurred'
          />
          <StaticImage
            src='../../images/clients/clients-icert-logo.png'
            alt='Λογότυπο της εταιρείας Icert'
            placeholder='blurred'
          />
          <StaticImage
            src='../../images/clients/clients-silkfab-logo.png'
            alt='Λογότυπο της εταιρείας Silkfab'
            placeholder='blurred'
          />
          <StaticImage
            src='../../images/clients/clients-tzeremesfyta-logo.png'
            alt='Λογότυπο της εταιρείας Τζερεμές Φυτά'
            placeholder='blurred'
          />
          <StaticImage
            src='../../images/clients/clients-pharmabeauty-logo.png'
            alt='Λογότυπο της εταιρείας Pharmabeauty'
            placeholder='blurred'
          />
        </ClientsGrid>
      </div>
    </ClientsSection>
  );
}

const ClientsSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 3.125rem;
  align-self: center;

  .section-headings-wrap {
    align-items: center;
  }
`;

const ClientsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 5rem;
  row-gap: 5rem;
  max-width: 1200px;
  margin: auto;
  @media screen and (max-width: 960px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 640px) {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 3rem;
  }
  .gatsby-image-wrapper {
    @media screen and (max-width: 640px) {
      width: 12.5rem;
    }
  }
`;
