import React from 'react';
import styled from 'styled-components';

export default function Hero() {
  return (
    <HeroSection id='hero'>
      <div className='section-inner'>
        <h1>
          Κατασκευή ιστοσελίδων & Eshop
          <br />
          Υπηρεσίες IT για την επιχείρηση σας
          <br />
          Σχεδιασμός εταιρικής ταυτότητας
        </h1>
      </div>
    </HeroSection>
  );
}

const HeroSection = styled.section`
  display: flex;
  h1 {
    @media screen and (max-width: 640px) {
      font-size: 1.5rem;
    }
  }
`;
