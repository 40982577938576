import React from 'react';
import { Link as LinkG } from 'gatsby';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

export default function ProjectsGridSingle(props) {
  const image = getImage(props.project.frontmatter.thumb);
  return (
    <ProjectsSingle key={props.project.id} to={props.project.frontmatter.link}>
      <GatsbyImage image={image} alt={props.project.frontmatter.thumbAlt} />
      <h4>{props.project.frontmatter.title}</h4>
    </ProjectsSingle>
  );
}

const ProjectsSingle = styled(LinkG)`
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: #000;
  row-gap: 1rem;
  .gatsby-image-wrapper {
    transition-duration: 0.2s;
    &:hover {
      transform: Scale(1.05);
    }
  }
  h4 {
    transition: 0.2s ease;
  }
`;
