import React from 'react';
import '../App.css';
import Header from '../components/global/Header';
import Footer from '../components/global/Footer';
import CookieBanner from '../components/global/CookieBanner';
import { MessengerChat } from 'react-messenger-chat-plugin';

export default function DefaultLayout({ children }) {
  return (
    <div className='layout'>
      <Header />
      <main>{children}</main>
      <CookieBanner />
      <MessengerChat
        pageId='112481810411385'
        language='el_GR'
        themeColor={'#000'}
        height={24}
        loggedInGreeting='Hello logged in user!'
        loggedOutGreeting='Hello stranger!'
        autoExpand={true}
        debugMode={true}
        onMessengerShow={() => {
          console.log('onMessengerShow');
        }}
        onMessengerHide={() => {
          console.log('onMessengerHide');
        }}
        onMessengerDialogShow={() => {
          console.log('onMessengerDialogShow');
        }}
        onMessengerDialogHide={() => {
          console.log('onMessengerDialogHide');
        }}
      />
      <Footer />
    </div>
  );
}
