import React from 'react';
import styled from 'styled-components';
import smallSquare from '../../images/small-square-21px.png';

export default function SingleService(props) {
  return (
    <ServicesSingle key={props.service.id}>
      <ServicesSingleHeadings>
        <img src={smallSquare} alt='' />
        <h4>{props.service.frontmatter.title}</h4>
      </ServicesSingleHeadings>
      <p>{props.service.frontmatter.description}</p>
    </ServicesSingle>
  );
}

const ServicesSingle = styled.article`
  display: flex;
  flex-direction: column;
  gap: 1.438rem;
  p {
    padding-left: 1.875rem;
  }
`;

const ServicesSingleHeadings = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    width: 1.313rem;
    height: 1.313rem;
    margin-right: 0.563rem;
  }
`;
